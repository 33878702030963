<template>
  <div>
    <template v-if="!loading">
      <div class="row">
        <div class="col-md-12 mb-2">
          <a href="#" class="btn btn-success float-end">{{ listing.status }}</a>
          <h3>Listing #{{ listing.id }}</h3>
        </div>
        <div class="col-md-5">
          <table class="table table-bordered bg-white">
            <tbody>
              <tr>
                <td>Description</td>
                <td>{{ listing.description }}</td>
              </tr>
              <tr>
                <td>Phone Number</td>
                <td>{{ listing.phone_number }}</td>
              </tr>
              <tr>
                <td>Region</td>
                <td>{{ listing.region }}</td>
              </tr>
              <tr>
                <td>Referred By</td>
                <td>
                  <router-link
                    :to="{
                      name: 'admin.users.show',
                      params: { id: listing.user.id },
                    }"
                    >{{ listing.user.name }}</router-link
                  >
                </td>
              </tr>
              <tr>
                <td>Referral Link</td>
                <td>
                  <referral-link :code="listing.user.referral_code" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-7">
          <div class="card">
            <div class="card-body">
              <a
                href="#"
                class="btn btn-primary btn-sm float-end"
                @click.prevent="update('called')"
                >Mark as called</a
              >
              <h4>Info</h4>
              <form @submit.prevent="">
                <table class="table table-bordered">
                  <tbody>
                    <tr>
                      <td width="20%">Number of calls</td>
                      <td>{{ listing.calls }}</td>
                    </tr>
                    <tr>
                      <td>Last Called</td>
                      <td>{{ listing.last_call }}</td>
                    </tr>
                    <tr>
                      <td>Response</td>
                      <td>
                        <textarea
                          rows="4"
                          class="form-control"
                          v-model="listing.response"
                          @blur="update('response')"
                          placeholder="Respone from owner"
                        ></textarea>
                      </td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>
                        <input
                          type="text"
                          placeholder="Owner Email"
                          v-model="listing.email"
                          class="form-control"
                          @blur="update('email')"
                        />
                        <div class="text-end mt-2" v-if="listing.email != null">
                          <a
                            href="#"
                            class="btn btn-primary btn-sm"
                            @click.prevent="update('send-email')"
                            >Send Invitation Email</a
                          >
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
              <div>
                <a
                  href="#"
                  v-if="listing.status != 'declined'"
                  class="btn btn-danger"
                  @click.prevent="update('declined')"
                  >Mark as declined</a
                >
                <a
                  href="#"
                  v-if="listing.status != 'hidden'"
                  class="btn btn-warning float-end"
                  @click.prevent="update('hidden')"
                  >Mark as hidden</a
                >
                <a
                  href="#"
                  v-if="listing.status != 'pending'"
                  class="btn btn-info float-end"
                  @click.prevent="update('pending')"
                  >Mark as pending</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      listing: {},
      loading: true,
    };
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$axios
        .get(`/api/v1/admin/listing-referrals/${this.$route.params.id}`)
        .then((response) => {
          this.listing = response.data.listing;
          this.loading = false;
        });
    },

    update(type) {
      let data = {
        type: type,
        listing: this.listing,
      };

      if (type == "send-email") {
        this.loading = true;
      }

      this.$axios
        .put(`/api/v1/admin/listing-referrals/${this.$route.params.id}`, data)
        .then(() => {
          if (type == "declined" || type == "hidden" || type == "called") {
            this.fetch();
          }

          if (type == "send-email") {
            this.loading = false;
            this.$swal("Email has been sent", "Sent", "success");
          }
        }).catch(() => {
          if (type == "send-email") {
            this.loading = false;
            this.$swal("Invalid Email", "Failed", "error");
          }
        });
    },
  },
};
</script>
